import * as React from 'react'

import { cn } from '#app/utils/misc.tsx'

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					"flex h-9 w-full rounded-md border border-input bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
					className
				)}
				ref={ref}
				{...props}
			/>
		)
	}
)
Input.displayName = "Input"

const InputWithIcon = React.forwardRef<HTMLInputElement, InputProps & { icon: React.ReactNode }>(
	({ className, icon, ...props }, ref) => {
	return (
		<div className="relative">
			<Input
				className={cn('pl-10', className)}
				ref={ref}
				{...props}
			/>
			<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
				{icon}
			</div>
		</div>
	)
})
InputWithIcon.displayName = "InputWithIcon"

export { Input, InputWithIcon }
